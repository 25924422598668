import tableStyles from 'src/Utils/CssModules/table.module.css';
import { IPartner } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo";

interface PartnersInfoProps {
    partnerInfo: IPartner[];
  }

const PartnersInfo:React.FC<PartnersInfoProps> = ({partnerInfo }) => {
    return(
        <>
                <div className={`${tableStyles.fixTableHead} ${tableStyles.roundedTable} px-4`}>
                    {partnerInfo  &&
                        <table style={{ width: '100%' }} data-testid="table" className={`table ${tableStyles.tableStriped}`}>
                            <thead className={`${tableStyles.tableHead}`}>
                                <tr key={'header'}>
                                    <th className={tableStyles.heading}>Partner Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {partnerInfo.map((user, i) => (
                                    <tr key={i}>
                                        <td className={tableStyles.data} style={{ fontWeight: '600' }}>{user.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
        </>
    )
}
export default PartnersInfo;