import { useState } from 'react';
import ButtonWithIcon from 'src/SharedComponents/ButtonWithIcon/ButtonWithIcon';
import CreatePartner from './CreatePartner/CreatePartner';
import PartnersInfo from './PartnersInfo/PartnersInfo';
import { useGetAuraSupportInfoWrapper } from 'src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfoWrapper';
import { useAuraSupportInfo } from 'src/Utils/Hooks/UseAuraInfoContext';

const Partners: React.FC = () => {
    const { getAuraSupportInfo } = useGetAuraSupportInfoWrapper();
    const { adminDetails } = useAuraSupportInfo();
    const [showCreatePartnerDialog, setShowCreatePartnerDialog] = useState<boolean>(false);
    
    return (
        <>
            <div className="col-auto d-flex justify-content-end">
                <ButtonWithIcon
                    backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                    onClick={() => { setShowCreatePartnerDialog(true) }}
                    label='New Partner'
                    icon='bi bi-plus-lg'
                    testId='newUser'
                    color='white'
                />
            </div>
            {showCreatePartnerDialog &&
                <CreatePartner
                    onClose={() => setShowCreatePartnerDialog(false)}
                    updatePartners={() => { getAuraSupportInfo() }} />
            }
            {adminDetails &&
                <div className='row'>
                     <div className='mt-5'>
                        <PartnersInfo
                            partnerInfo={adminDetails.partners}
                        />
                    </div>
                </div>
            }
        </>
    )
}
export default Partners;