import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import NavbarContentWrapper from "../LayoutWrapper/NavbarContentWrapper";
import NavbarInfoContainer from "../NavbarInfoContainer/NavbarInfoContainer";
import { useEffect } from "react";
import { useGetAuraSupportInfoWrapper } from "src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfoWrapper";

const MainContent : React.FC = () => {
    const { adminDetails, auraSupportLoading, getAuraSupportInfo } = useGetAuraSupportInfoWrapper()


    useEffect(() => {
        if(!adminDetails) {
            getAuraSupportInfo()
        }
    }, [getAuraSupportInfo, adminDetails ])
    return (
        <>
            {adminDetails && !auraSupportLoading &&
                <>
                    <div style={{marginBottom: '40px'}}>
                        <Header />
                        <NavbarInfoContainer />
                    </div>
                    <NavbarContentWrapper >
                        <Outlet />
                    </NavbarContentWrapper>
                </>
            }
        </>
    )
}
export default MainContent;

