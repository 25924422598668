import React, { ReactNode, useState } from "react";
import "./FlushAccordion.css"

interface IFlushAccordian {
    title: string;
    children: ReactNode;
    id: string;
    defaultOpen?: boolean; 
}

const FlushAccordion: React.FC<IFlushAccordian> = ({ title, children, id, defaultOpen = true }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    return (
        <>
            <div className="accordion accordion-flush mt-3" id={`accordionFlushExample${id}`}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`flush-headingOne${id}`}>
                        <button
                            className={`accordion-button ${isOpen ? "" : "collapsed"}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapseOne${id}`}
                            aria-expanded={isOpen ? "true" : "false"}
                            aria-controls={`flush-collapseOne${id}`}
                            style={{ paddingRight: '30px' }}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <b>{title}</b>
                        </button>
                    </h2>
                    <div
                        id={`flush-collapseOne${id}`}
                        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
                        aria-labelledby={`flush-headingOne${id}`}
                        data-bs-parent={`#accordionFlushExample${id}`}
                    >
                        <div className="accordion-body mb-3">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlushAccordion;
