import GridView from "src/SharedComponents/GridView/GridView";
import LogoText from "src/SharedComponents/LogoText/LogoText";
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import mapPinLogo from 'src/images/map-pin.svg';
import { useAuraSupportInfo } from "src/Utils/Hooks/UseAuraInfoContext";

const Contact: React.FC = () => {
    const { adminDetails } = useAuraSupportInfo();

    return (
        <>
        {adminDetails && 
           <GridView title="Hexagon Aura Reality AG">
                <div className="d-flex flex-column">
                    <LogoText logoSrc={mailLogo} text={adminDetails.auraSupportInfo?.email} />
                    <LogoText logoSrc={phoneLogo} text={adminDetails.auraSupportInfo?.contactNumber} />
                    <LogoText logoSrc={mapPinLogo} text={adminDetails.auraSupportInfo?.streetAddress} />
                </div>
                <div>
                    <div className="row mt-3">
                            <div className="mb-2 col-9">
                                <b>Support Contact</b>
                            </div>
                    </div>
                    <LogoText logoSrc={mailLogo} text={adminDetails.auraSupportInfo?.supportEmail} />
                    <LogoText logoSrc={phoneLogo} text={adminDetails.auraSupportInfo?.supportContactNumber} />
                </div>
            </GridView>
        }
        </>
    )
}
export default Contact;