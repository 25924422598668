import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import './AppLayout.css';
import React, { useEffect, useState } from 'react';
import { InteractionType } from '@azure/msal-browser';
import MainContent from './MainContent';
import { InfobarDetails, InfoContext } from 'src/Utils/Hooks/UseAuraInfoContext';
import { InfoMessageType } from 'src/SharedComponents/InfoBar/InfoBar';
import { IAuraSupport } from 'src/Utils/Hooks/useGetAdminInfo/useGetAuraSupportInfo';

const MainLayout: React.FC = () => {
    const [show, setShow] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [adminDetails, setAdminInfo] = useState<IAuraSupport>();
    const [infoMessageDetails, setInfoMessageDetails] = useState<InfobarDetails>({message:'', messageType:InfoMessageType.none})
    const { login, error } = useMsalAuthentication(InteractionType.Redirect);
    const isAuthenticated = useIsAuthenticated();
    
    useEffect(() => {
        if (error) {
            login(InteractionType.Popup);
        }
    }, [error, login]);

    return (
        <>
            {isAuthenticated && 
                <>
                   <InfoContext.Provider value={{show,  setShow, infoMessageDetails, setInfoMessageDetails, loader, setLoader, adminDetails, setAdminInfo}}>
                      <MainContent />
                   </InfoContext.Provider>
                </>
            }
        </>
    );
};
export default React.memo(MainLayout);
