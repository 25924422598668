import { useEffect } from "react";
import { useAuraSupportInfo } from "../UseAuraInfoContext";
import { useGetAuraSupportInfo } from "./useGetAuraSupportInfo"
import { logger } from "src/Utils/LoggerService/LoggerService";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";

export const useGetAuraSupportInfoWrapper = () => {
    const {adminDetails, auraSupportLoading, error, getAuraSupportInfo} = useGetAuraSupportInfo();
    const { setInfobar, setLoader, reset, setAdminInfo } = useAuraSupportInfo();

    useEffect(() => {
        if (error) {
            logger.logError(`Failed to get all support details.`)
            setInfobar({message: error, messageType: InfoMessageType.error});
        }
    }, [error, setInfobar])
    
    useEffect(()=> {
        setLoader(auraSupportLoading);
    }, [auraSupportLoading, setLoader])

    useEffect(() => {
        reset();
    },[reset])

    useEffect(() => {
        if(adminDetails){
            setAdminInfo(adminDetails);
        }
    }, [adminDetails, setAdminInfo])

    return {adminDetails, error, auraSupportLoading, getAuraSupportInfo}
}