import {useCallback, useEffect} from 'react';
import {Config} from '../../ConfigService/LoadConfig';
import { useGetRequest } from '../ApiWrappers/useGetRequest';

export interface IAuraSupportInfo{
    auraAdminId: string,
    name: string, 
    email: string,
    contactNumber: string,
    streetAddress: string,
    zipCode: string,
    city: string,
    country: string,
    isClosed: string,
    supportEmail: string,
    supportContactNumber: string,
    supportMessagingChannel: string,
    supportMessagingChannelType: string
}

export interface IUser{
    userId:string,
    email:string,
    firstName:string,
    lastName:string
}

export interface IPartner{
    distributorId: string,
    name: string,
    email?: string,
    contactNumber?: string,
    streetAddress?: string,
    city?: string,
    country?: string,
    setUpDate?: string,
    devicesCount?: number,
    auraSupportPartnerAdminUserEmail?: string,
    auraSupportPartnerAdminUserStatus?: string
}

export interface IDevice{
    deviceId: string,
    distributorId: string,
    distributorName: string,
    serialNumber: string,
    firmwareVersion: string,
    assignedDate: string,
    isAssignedToDistributor: boolean
}

export interface IAuraSupport{
    auraSupportInfo: IAuraSupportInfo,
    users: IUser[],
    partners: IPartner[],
    devices: IDevice[]
}

export const useGetAuraSupportInfo = () => {
    const scope = Config.GetConfigData().auraWebService?.scopes[0];
    const baseUrl = Config.GetConfigData().serviceUrl;
    const requestUrl = `${baseUrl}api/v1/aura-support/me`;
    const [getDetails, adminDetails, auraSupportLoading, error] = useGetRequest<IAuraSupport>();

    useEffect(() => {
        getDetails(scope, requestUrl);
    },[getDetails, baseUrl, scope, requestUrl]);

    const getAuraSupportInfo = useCallback(() => {
        getDetails(scope, requestUrl)
    }, [getDetails, scope, requestUrl])

    return{
        getAuraSupportInfo,
        adminDetails,
        auraSupportLoading,
        error
    }
}